@import './Config.scss';

#wrapper-login {
  width: 400px;
  margin: 100px auto;
  padding: 30px;
  border: 1px solid $BORDER_COLOR;
  border-radius: 10px;
  
  button[type=submit] {
    float: right;
  }
}