.ant-form-item {
  margin-bottom: 10px;
  .ant-form-item-label > label {
    font-size: 13px;
  }
}
.ant-form-item-explain, .ant-form-item-extra {
  font-size: 12px;
}
.ant-btn {
  border-radius: 3px;
  &.ant-btn-primary span {
    color: #FFF;
    svg {
      fill: #FFF;
    }
  }
}
.ant-row {
  .ant-col {
    &:first-child {
      width: 100px;
    }
    & > label {
      width: 100%;
      margin-right: 15px;
      &::after {
        display: none;
      }
    }
  }
}
.ant-menu {
  width: 199px;
  border-right: none;
  // height: 100%;
  .ant-menu-item-active {
    span {
      color: #1890ff;
    }
  }
  .ant-menu-item-selected::after {
    transform: scaleY(1);
    opacity: 1;
  }
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, 
.ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, 
.ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, 
.ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), 
.ant-menu-vertical-left .ant-menu-item:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item:not(:last-child), 
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-table {
  .ant-table-thead {
    & > tr > th {
      text-align: center;
    }
  }
  .ant-table-tbody {
    & > tr > td {
      text-align: center;
    }
  }

}