.user-count {
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 8px;
}
.ant-btn.btn-reload, .filtered-count {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  span {
    font-size: inherit;
  }
}
.filtered-count {
  right: 90px;
}
.ant-table-filter-dropdown > div {
  padding: 20px;
  border: 1px solid #DDD;
}
.ant-table-filter-dropdown > div > p {
  font-size: 13px;
  padding-bottom: 5px;
}
.ant-table-filter-dropdown .ant-picker {
  width: 100%;
}
.ant-picker-input > input {
  font-size: 13px;
}