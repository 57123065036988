.coupon-code > span {
  cursor: pointer;
  &:HOVER {
    text-decoration: underline;
  }
}
.search-box {
  text-align: center;
  padding: 10px 0;
  & > div {
    display: inline-block;
    max-width: 200px;
    vertical-align: top;
    margin: 0 2px;
  }
}
#form-export {
  .ant-form-item {
    display: inline-block;
    input {
      width: 100px;
    }
  }
  button {
    margin-left: 5px;
  }
}