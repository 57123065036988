html, body {
  margin: 0;
  padding: 0;
}
div, p, a, span, h1, h2, h3, h4, h5, h6, section, article, figure {
  position: relative;
  box-sizing: border-box;
  outline-style: none;
  font-size: 14px;
  margin: 0;
  color: #333;
}
#root, .wrapper-main {
  height: 100%;
}
.clearfix {
  zoom: 1;
}
.clearfix::before, .clearfix::after {
  display:block; 
  content: ''; 
  line-height: 0;
}
.clearfix::after {
  clear: both;
}
.wrapper-main {
  padding-top: 50px;
}
.wrapper-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #DFDFDF;
  line-height: 50px;
  text-align: center;
}
.wrapper-aside {
  position: absolute;
  top: 50px;
  left: 0;
  width: 200px;
  height: calc(100% - 50px);
  border-right: 1px solid #F0F0F0;
}
.wrapper-content {
  position: absolute;
  top: 50px;
  left: 200px;
  min-width: 1000px;
  width: calc(100% - 200px);
  height: calc(100% - 50px);
  padding: 20px;
}
.title-bar {
  &.title {
    padding: 0 0 8px;
    font-weight: 700;
    height: 32px;
    line-height: 24px;
    svg {
      transform: scale(1.1);
    }
    button {
      float: right;
      margin-left: 5px;
      span {
        font-size: 13px;
      }
    }
  }
}
.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 255.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.spinner.show {
  transition: all 0.3s;
  opacity: 0.8;
  visibility: visible;
}
.spinner > span {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 20px);
}